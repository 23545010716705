import Environment from "./Environment"

const scopes = ["reservations:read", "employees:read"]

export const storeAccessToken = (accessToken: string): void =>
    sessionStorage.setItem("access_token", accessToken)

export const getAccessToken = (): string | null =>
    sessionStorage.getItem("access_token")

export const removeAccessToken = (): void =>
    sessionStorage.removeItem("access_token")

export const getIDPLoginURI = (): string => {
    return `${Environment.idpEndpoint}/${`authorize?audience=${
        Environment.idpTripAPIAudience
    }&scope=${scopes.join(" ")}&response_type=token&client_id=${
        Environment.idpClientId
    }&redirect_uri=${Environment.idpRedirectURI}`}`
}

export const invalidateAccessToken = (): void => removeAccessToken()

export const isAuthenticated = (): boolean => {
    const accessToken = getAccessToken()

    if (!accessToken) {
        return false
    }

    return true
}
