/* eslint-disable @typescript-eslint/no-explicit-any */
import { TripUser, IDPUser, Reservation } from "./types"
import axios from "axios"
import Environment from "./Environment"
import { getAccessToken } from "./auth"

const parseTripUserV2Response = (response: any): TripUser => {
    const data = response.data
    return {
        users: data.attributes.users.map((u: any) => {
            const user: IDPUser = {
                userId: u.user_id,
                email: u.email,
                givenName: u.given_name,
                familyName: u.family_name,
            }

            return user
        }),
        reservations: data.attributes.reservations.map((r: any) => {
            const reservation: Reservation = {
                reservationId: r.reservation_id,
                unitId: r.unit_id,
                email: r.email,
                confirmationCode: r.confirmation_code,
                firstName: r.first_name,
                lastName: r.last_name,
                isPrimary: r.is_primary,
                source: r.source,
            }

            return reservation
        }),
    }
}

export const fetchTripUserV2 = async (
    email: string
): Promise<
    { error: false; result: TripUser } | { error: true; status: number }
> => {
    const localTripUser = sessionStorage.getItem(`trip-user-v2-${email}`)

    if (localTripUser) {
        return {
            error: false,
            result: JSON.parse(localTripUser),
        }
    }

    const url = `${Environment.tripAPIEndpoint}/v1/users/${email}`

    try {
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${getAccessToken()}`,
            },
        })

        const tripUser = parseTripUserV2Response(response.data)
        sessionStorage.setItem(
            `trip-user-v2-${email}`,
            JSON.stringify(tripUser)
        )
        return { error: false, result: tripUser }
    } catch (err) {
        return {
            error: true,
            status: Number(err?.response?.status) || 500,
        }
    }
}
