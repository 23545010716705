import React from "react"
import { UserCardProps, UserCardState } from "../types"
import Card from "react-bootstrap/Card"
import ReservationDetailsComponent from "./ReservationDetailsComponent"
import IDPUserInfoCard from "./IDPUserInfoComponent"

export default class UserCardComponent extends React.Component<
    UserCardProps,
    UserCardState
> {
    constructor(props: UserCardProps) {
        super(props)
        this.state = {
            loading: false,
        }
    }

    public render(): JSX.Element {
        if (this.props.tripUser === undefined) {
            return <div></div>
        }

        return (
            <div>
                <Card>
                    <div>
                        <Card.Header style={{ borderBottom: "0" }}>
                            User
                        </Card.Header>
                        <IDPUserInfoCard
                            idpUser={this.props.tripUser?.users[0]}
                        />
                        <br />
                    </div>
                    <Card.Header
                        style={{
                            borderBottom: "0",
                        }}
                    >
                        Reservations
                    </Card.Header>
                    <ReservationDetailsComponent
                        reservations={this.props.tripUser.reservations ?? []}
                    />
                </Card>
            </div>
        )
    }
}
