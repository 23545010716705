import React from "react"
import { ReservationDetailsProps } from "../types"
import Table from "react-bootstrap/Table"
import Button from "react-bootstrap/Button"

export default class ReservationDetailsComponent extends React.Component<
    ReservationDetailsProps,
    {}
> {
    public render(): JSX.Element {
        return (
            <Table className="reservation-details">
                <tbody>
                    <tr>
                        <th>Reservation</th>
                        <th>Primary</th>
                        <th>Source</th>
                        <th>Unit</th>
                        <th>First</th>
                        <th>Last</th>
                        <th>Email</th>
                        <th>Confirmation</th>
                    </tr>
                    {this.props.reservations.map((details) => (
                        <tr key={details.reservationId}>
                            <td>{details.reservationId}</td>
                            <td>{String(details.isPrimary)}</td>
                            <td>{details.source}</td>
                            <td>{details.unitId ?? "-"}</td>
                            <td>{details.firstName ?? "-"}</td>
                            <td>{details.lastName ?? "-"}</td>
                            <td>{details.email ?? "-"}</td>
                            <td>{details.confirmationCode ?? "-"}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
}
