import React, { RefObject } from "react"
import Form from "react-bootstrap/Form"

interface UserSearchProps {
    onSubmit: (text: string) => void
}

export default class UserSearchComponent extends React.Component<
    UserSearchProps,
    {}
> {
    private searchRef: RefObject<any>

    constructor(props: UserSearchProps) {
        super(props)

        this.searchRef = React.createRef<any>()
        this.onSubmit = this.onSubmit.bind(this)
    }

    public render(): JSX.Element {
        return (
            <Form.Control
                size="lg"
                type="text"
                placeholder="Search by email address"
                ref={this.searchRef}
                onKeyPress={this.onSubmit}
            />
        )
    }

    private onSubmit(event: React.KeyboardEvent<HTMLInputElement>): void {
        if (event.key === "Enter") {
            const text = this.searchRef.current?.value
            this.props.onSubmit(text)
        }
    }
}
