/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import "./App.css"
import NavBarComponent from "./components/NavBarComponent"
import UsersComponent from "./components/UsersComponent"
import { storeAccessToken, isAuthenticated, getIDPLoginURI } from "./auth"
import jsonwebtoken from "jsonwebtoken"
import UnauthorizedUserComponent from "./components/UnauthorizedUserComponent"

class App extends React.Component<{}, {}> {
    public render(): JSX.Element {
        const accessTokenMatch = window.location.href.match(
            "access_token=(.+)&.+"
        )

        if (accessTokenMatch !== null) {
            const accessToken = accessTokenMatch[1]
            const decodedToken = jsonwebtoken.decode(accessToken) as any

            if (!decodedToken?.scopes.includes("employees:read")) {
                // Logged in user is not an employee
                return (
                    <div>
                        <NavBarComponent />
                        <br />
                        <div className="App container-fluid">
                            <UnauthorizedUserComponent />
                        </div>
                    </div>
                )
            }

            storeAccessToken(accessToken)

            // Remove access_token from URL
            window.location.href = window.location.href.substring(
                0,
                window.location.href.indexOf("#access_token")
            )
        }

        if (!isAuthenticated()) {
            // Redirect to IdP
            window.location.href = getIDPLoginURI()
            return <div></div>
        }

        return (
            <div>
                <NavBarComponent />
                <br />
                <div className="App container-fluid">
                    <UsersComponent />
                </div>
            </div>
        )
    }
}

export default App
