import React from "react"
import { IDPUserInfoCardProps } from "../types"
import Table from "react-bootstrap/Table"
import ClipLoader from "react-spinners/ClipLoader"

export default class IDPUserInfoComponent extends React.Component<
    IDPUserInfoCardProps,
    {}
> {
    public render(): JSX.Element {
        return (
            <div>
                <Table>
                    {this.props.idpUser !== undefined ? (
                        <tbody>
                            <tr>
                                <th>User ID</th>
                                <th>Email</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                            </tr>
                            <tr>
                                <td>{this.props.idpUser.userId}</td>
                                <td>{this.props.idpUser.email}</td>
                                <td>{this.props.idpUser.givenName}</td>
                                <td>{this.props.idpUser.familyName}</td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <th>
                                    <ClipLoader
                                        size={20}
                                        color={"#416777"}
                                        loading={true}
                                    />
                                </th>
                            </tr>
                            <tr></tr>
                        </tbody>
                    )}
                </Table>
            </div>
        )
    }
}
