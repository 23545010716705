import Navbar from "react-bootstrap/NavBar"
import React from "react"
import logo from "../logo.svg"

export default class NavBarComponent extends React.Component {
    public render(): JSX.Element {
        return (
            <Navbar>
                <Navbar.Brand href="#">
                    <img src={logo} alt="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </Navbar>
        )
    }
}
