/* eslint-disable no-useless-escape */
import React from "react"
import { UsersState } from "../types"
import UserCardComponent from "./UserCardComponent"
import UserSearchComponent from "./UserSearchComponent"
import ClipLoader from "react-spinners/ClipLoader"
import { fetchTripUserV2 } from "../tripAPI"
import { invalidateAccessToken } from "../auth"

export default class UsersComponent extends React.Component<{}, UsersState> {
    constructor(props: {}) {
        super(props)
        this.state = { loading: false }
        this.searchBoxOnSubmit = this.searchBoxOnSubmit.bind(this)
    }

    public render(): JSX.Element {
        return (
            <div>
                <UserSearchComponent onSubmit={this.searchBoxOnSubmit} />
                <br />
                <ClipLoader
                    size={20}
                    color={"#416777"}
                    loading={this.state.loading}
                />
                <UserCardComponent tripUser={this.state.tripUser} />
                <br />
            </div>
        )
    }

    private searchBoxOnSubmit(text: string): void {
        this.setState({
            loading: true,
            tripUser: undefined,
        })
        ;(async (): Promise<void> => {
            try {
                const tripUser = await fetchTripUserV2(text)
                if (!tripUser.error) {
                    this.setState({ tripUser: tripUser.result })
                } else {
                    if (tripUser.status === 401 || tripUser.status === 403) {
                        // Redirect to IdP
                        invalidateAccessToken()
                        window.location.reload()
                    }
                }
            } catch {
                this.setState({
                    tripUser: undefined,
                })
            } finally {
                this.setState({ loading: false })
            }
        })()
    }
}
