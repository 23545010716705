import React from "react"
import Alert from "react-bootstrap/Alert"
import Button from "react-bootstrap/Button"
import Environment from "../Environment"

export default class UnauthorizedUserComponent extends React.Component<{}, {}> {
    public render(): JSX.Element {
        return (
            <div>
                <Alert variant="danger">
                    <Alert.Heading>
                        You are not authorized to view this page
                    </Alert.Heading>
                    <p>
                        If you are an employee, please make sure to login using
                        OneLogin.
                    </p>
                    <Button variant="info" href={Environment.idpLogoutURI}>
                        Sign out
                    </Button>
                </Alert>
            </div>
        )
    }
}
