export default class Environment {
    public static get tripAPIEndpoint(): string {
        return this.getEnvironmentVariable("REACT_APP_TRIP_API_BASE_URL")
    }

    public static get idpClientId(): string {
        return this.getEnvironmentVariable("REACT_APP_IDP_CLIENT_ID")
    }

    public static get idpEndpoint(): string {
        return this.getEnvironmentVariable("REACT_APP_IDP_ENDPOINT")
    }

    public static get idpTripAPIAudience(): string {
        return this.getEnvironmentVariable("REACT_APP_IDP_TRIP_API_AUDIENCE")
    }

    public static get idpRedirectURI(): string {
        return this.getEnvironmentVariable("REACT_APP_IDP_REDIRECT_URI")
    }

    public static get idpLogoutURI(): string {
        return this.getEnvironmentVariable("REACT_APP_IDP_LOGOUT_URI")
    }

    private static getEnvironmentVariable(name: string): string {
        if (!process.env[name]) {
            throw new Error(`${name} not set`)
        }

        return String(process.env[name])
    }
}
